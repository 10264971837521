/* eslint-disable camelcase */
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { createQueryParamsAsString } from 'helpers/requestHelpers';
import {
  insertCustomExerciseDataIntoBuilderData,
  prepareBuilderDataToSave,
  prepareExercisesToSave,
} from 'helpers/programHelper';
import { getRequest, postRequest, putRequest } from './base';

// rtm: userWhoSubmittedConsent, userGiving, userSubjectToConsent === current user
//  => case level
// communication: userWhoSubmittedConsent === currentUser, userGiving, userSubjectToConsent === participantId
//  => user level
const formatConsent = ({ consent, userId, providerId, caseId }) => {
  if (consent && consent.length > 0) {
    return consent.reduce((acc, { id, category }) => {
      if (category === 'communication') {
        acc.push({
          consentSubmissionSource: 'portal',
          userWhoSubmittedConsent: providerId,
          userGivingConsent: userId,
          userSubjectToConsent: userId,
          caseId,
          tenantsConsentConfigsId: id,
        });
      }
      if (category === 'rtm') {
        acc.push({
          consentSubmissionSource: 'portal',
          userWhoSubmittedConsent: providerId,
          userGivingConsent: providerId,
          userSubjectToConsent: providerId,
          caseId,
          tenantsConsentConfigsId: id,
        });
      }
      return acc;
    }, []);
  }
  return undefined;
};

export const createUser = async (user) => {
  return postRequest('/user', user).then((response) => response.data);
};

export const createParticipant = async (participant) => {
  const toCreate = { ...participant };
  toCreate.role = 1;
  toCreate.active = 1;
  toCreate.date = new Date();
  return postRequest('/user', toCreate).then((response) => response.data);
};

export const editParticipant = async (info) => {
  return postRequest('users/edit', info).then((response) => response.data);
};

export const assignParticipantToProvider = async (
  participant_id,
  provider_id
) => {
  return postRequest('users/assigntomedic', {
    user_id: participant_id,
    medic_id: provider_id,
  }).then((response) => response.data);
};

export const assignParticipantToProgram = async ({
  caseId,
  consent: initConsent,
  participantId,
  programId,
  providerId,
  serviceType,
  shareableCaseId,
  userId,
}) => {
  const consent = formatConsent({
    consent: initConsent,
    userId: participantId,
    providerId,
    caseId: shareableCaseId,
  });

  if (consent) {
    await editParticipant({
      edit: true,
      user_id: userId.toString(),
      consent,
    });
  }

  return postRequest('users/assignprogram', {
    user_id: participantId,
    program_id: programId,
    date: new Date(),
    service_type: serviceType,
    case_id: caseId,
  }).then((response) => response.data);
};

export const getParticipantOverview = async (participant_id) => {
  return getRequest(`overview/${participant_id || ''}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getAllowParticipant = async (participantId, orgId) => {
  return getRequest(
    `participantOrg?participantId=${participantId}&orgSearch=${orgId}`
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getParticipantProgress = async (
  participantId,
  case_id,
  date = null
) => {
  return getRequest(
    `today/statsbyid?user_id=${participantId ?? ''}&case_id=${case_id}&date=${
      date || moment().format('YYYY-MM-DD')
    }`
  )
    .then((response) => {
      if (response.status === 200) return response.data;
      return Promise.reject(response.statusText);
    })
    .catch((err) => console.log(err));
};

export const getParticipantNotes = async (participant_id, case_id, signal) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  return getRequest(
    `medicnotes/${participant_id}?case_id=${case_id}`,
    config
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getParticipantTimeTrackNotes = async (
  participant_id,
  case_id,
  signal
) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  return getRequest(
    `timetracking/${participant_id}?case_id=${case_id}`,
    config
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getParticipantTimeTrackerNotes = async (
  participant_id,
  case_id,
  signal
) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  return getRequest(
    `timetracking/${participant_id}?case_id=${case_id}`,
    config
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const addParticipantNote = async (data) => {
  return postRequest('medicnotes', data).then((response) => response.data);
};

export const saveTimetracker = async (data) => {
  return postRequest('timetracking', data).then((response) => response.data);
};

export const editTimetracker = async (data, id) => {
  return putRequest(`/timetrack/${id}`, data).then((response) => response.data);
};

export const addParticipantFile = async (
  participant_id,
  case_id,
  fileName,
  file
) => {
  const response = await postRequest('fileupload/presigned', {
    user_id: participant_id,
    case_id,
    file_name: fileName,
    contentType: file.type,
  });

  const { data } = response;
  await fetch(data.presigned_upload_url, {
    method: 'PUT',
    body: file,
  });

  return response;
};

export const getOrganizations = async () => {
  return getRequest(`organizations/getfirstlevel`).then((response) => {
    if (response.status === 200) return response.data;
    if (response.status === 204) return [];
    return Promise.reject(response.statusText);
  });
};

export const getGroups = async (orgId, signal) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  return getRequest(
    `organizations/getgroups${
      orgId && orgId.length > 0 ? `?orgId=${orgId}` : ''
    }`,
    config
  ).then((response) => {
    if (response.status === 200) return response.data;
    if (response.status === 204) return [];
    return Promise.reject(response.statusText);
  });
};

export const getProviders = (groupIds, orgIds, signal) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  const params = {
    groupId: groupIds,
    orgId: groupIds ? null : orgIds,
  };
  return getRequest(
    `/providers/getbyorg?${createQueryParamsAsString(params)}`,
    config
  ).then((response) => {
    if (response.status === 200) return response.data;
    if (response.status === 204) return [];
    return Promise.reject(response.statusText);
  });
};

export const getParticipantFiles = (participantId, case_id, signal) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  return getRequest(
    `userfiles/${participantId}?case_id=${case_id}`,
    config
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const assignLevel = (participantId, case_id, levelId) => {
  return postRequest('program/setLevel', {
    participantId,
    case_id,
    level: levelId,
  }).then((response) => response.data);
};

export const getProviders2 = (
  search = '',
  orgId,
  groupId,
  limit = 5,
  offset
) => {
  return getRequest(
    `providers?search=${search ? encodeURIComponent(search) : ''}${
      orgId ? `&orgId=${orgId}` : ''
    }${groupId ? `&groupId=${groupId}` : ''}${
      limit > 0 ? `&limit=${limit}` : ''
    }${offset > 0 ? `&offset=${offset}` : ''}`
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getStatusOptions = (showHidden = false) => {
  return getRequest(`status?show_hidden=${showHidden}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getCategoryOptions = () => {
  return getRequest(`tags`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getPrimaryInsuranceOptions = () => {
  return getRequest(`status`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getProviderOptions = () => {
  return getRequest(`status`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getTags = (qParams) => {
  return getRequest(`tags?${new URLSearchParams(qParams).toString()}`).then(
    (response) => {
      if (response.status === 200) return response.data;
      return Promise.reject(response.statusText);
    }
  );
};

export const getCareNavigators = () => {
  return getRequest('cns').then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getParticipants = async ({
  tags,
  locations,
  providers,
  limit,
  page,
  search = '',
  categories,
  has_active_case,
  sort_field,
  sort_order,
  signal,
}) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  const queryParams = {
    tags,
    locations,
    providers,
    limit,
    page,
    search: search ? encodeURIComponent(search) : '',
    has_active_case,
    sort_field,
    sort_order,
    category_tags: categories,
  };

  return getRequest(
    `participants?${createQueryParamsAsString(queryParams)}`,
    config
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getParticipantFlagsList = async ({ id, case_id, signal }) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  return getRequest(
    `participants/${id}/flags/?case_id=${case_id}`,
    config
  ).then((response) => {
    if (response.status === 200) {
      const { flags } = response.data;

      return flags;
    }
    return Promise.reject(response.statusText);
  });
};

export const resolveParticipantFlagById = async (params) => {
  console.log('calling resolve participant flag', { params });
  const { id } = params;
  return putRequest(`participants/flags/${id}/resolve`).then((response) => {
    if (response.status === 200) {
      console.log('success resolved', { response });
      const { is_resolved } = response.data;
      return is_resolved;
    }
    return Promise.reject(response.statusText);
  });
};

export const getTimeTrackerNoteTypes = async () => {
  return getRequest(`timetrack/types`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const createAndAssignProgram = async ({
  builderData: initBuilderData,
  caseId,
  consent: initConsent,
  customExerciseData,
  isTemplate: template,
  participantId,
  programId,
  programName: name,
  providerId,
  serviceTypeId: serviceType,
  shareableCaseId,
  userId,
}) => {
  const consent = formatConsent({
    consent: initConsent,
    userId: participantId,
    providerId,
    caseId: shareableCaseId,
  });
  // NOTE: await user edit - this is based on the legacy sagas and makes
  // ZERO sense why this isn't part of a single, unified call to create
  // and assign the program. eventually, we can just spin up a new endpoint
  // and keep this function i guess
  const editUserData = {
    case: { id: caseId },
    category_tag: serviceType,
    edit: true,
    user_id: userId,
    consent,
  };

  await editParticipant(editUserData);

  // NOTE: this is legacy functionality
  const exerciseDataWithCustomData = insertCustomExerciseDataIntoBuilderData({
    initBuilderData,
    customExerciseData,
  });

  const exercises = prepareExercisesToSave(
    exerciseDataWithCustomData,
    !!programId
  );

  const builderData = prepareBuilderDataToSave(
    initBuilderData,
    !!programId,
    customExerciseData
  );

  const data = {
    builderData: JSON.stringify(builderData),
    caseId,
    editId: programId,
    exercises,
    isEditingTemplate: !!(template && programId),
    name,
    participantId,
    template: !!template,
    serviceType,
  };

  const resp = await postRequest('/programs', data);

  return resp.data;
};

export const createHepTemplate = async ({
  templateId = null,
  builderData: initBuilderData,
  customExerciseData,
  templateName: name,
  isShared = false,
  tags,
}) => {
  // NOTE: this is legacy functionality
  const builderData = prepareBuilderDataToSave(
    initBuilderData,
    !!templateId,
    customExerciseData
  );

  const data = {
    builderData: JSON.stringify(builderData),
    name,
    tags: isEmpty(tags) ? undefined : tags,
    isShared,
  };

  const resp = templateId
    ? await putRequest(`/program-templates/${templateId}`, data)
    : await postRequest(`/program-templates`, data);

  return resp.data;
};
