import Immutable from 'seamless-immutable';
import { filters as filterKeys } from 'constants/exercisesFilters';

import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions(
  {
    fetchExercises: ['filters', 'search', 'activeFilters'],
    fetchExercisesSuccess: ['exercises'],
    setExercisesErrors: ['errors'],
    fetchAutoComplete: ['searchText'],
    fetchAutoCompleteSuccess: ['autoComplete'],
    setAutoCompleteErrors: ['autoCompleteErrors'],
    setAutoCompleteLoading: ['isLoadingAutoComplete'],
    setSearchText: ['searchText'],
    setNavigation: ['navigation'],
    setSearch: ['search'],
    setFilters: ['filters'],
    setActiveFilters: ['activeFilters'],
    setExercisesLoading: ['isLoadingExercises'],
    fetchExercisesFilters: [],
    fetchExercisesFiltersSuccess: ['exercisesFilters'],
    setExercisesFiltersErrors: ['exercisesFiltersErrors'],
    setExercisesFiltersLoading: ['isLoadingExercisesFilters'],
  },
  {
    prefix: '@BE-EXERCISES/',
  }
);

export const ExercisesTypes = Types;
export const ExercisesActions = Creators;

export const INITIAL_STATE = Immutable({
  isLoadingExercises: false,
  exercises: [],
  errors: [],
  isLoadingAutoComplete: false,
  autoComplete: [],
  autoCompleteErrors: [],
  search: '',
  searchText: '',
  filters: {
    [filterKeys.favoritesOnly]: false,
    [filterKeys.aliasOnly]: false,
    [filterKeys.sharedExercises]: false,
  },
  navigation: {
    currentPage: 1,
    totalPages: 1,
    limit: 20,
  },
  activeFilters: {},
  // deprecated
  exercisesFilters: [],
  exercisesFiltersErrors: [],
  isLoadingExercisesFilters: false,
});

export const ExercisesSelectors = {
  getExercises: ({ exercises }) => exercises.exercises,
  getNavigation: ({ exercises }) => exercises.navigation,
  getFilters: ({ exercises }) => exercises.filters,
  getSearch: ({ exercises }) => exercises.search,
  getErrors: ({ exercises }) => exercises.errors,
  getExercisesLoading: ({ exercises }) => exercises.isLoadingExercises,
  getAutoCompleteErrors: ({ exercises }) => exercises.autoCompleteErrors,
  getAutoCompleteLoading: ({ exercises }) => exercises.isLoadingAutoComplete,
  getAutoComplete: ({ exercises }) => exercises.autoComplete,
  getSearchText: ({ exercises }) => exercises.searchText,
  getActiveFilters: ({ exercises }) => exercises.activeFilters,
  getExercisesFilters: ({ exercises }) => exercises.exercisesFilters,
  getExercisesFiltersErrors: ({ exercises }) =>
    exercises.exercisesFiltersErrors,
  getExercisesFiltersLoading: ({ exercises }) =>
    exercises.isLoadingExercisesFilters,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const fetchExercisesSuccess = (state, { exercises }) =>
  state.merge({ exercises, errors: [] });

const setExercisesLoading = (state, { isLoadingExercises }) =>
  state.merge({ isLoadingExercises });

const setExercisesErrors = (state, { errors }) =>
  state.merge({
    exercises: [],
    errors,
  });

const fetchAutoCompleteSuccess = (state, { autoComplete }) => {
  return state.merge({ autoComplete, autoCompleteErrors: [] });
};

const setAutoCompleteLoading = (state, { isLoadingAutoComplete }) => {
  return state.merge({ isLoadingAutoComplete });
};

const setAutoCompleteErrors = (state, { autoCompleteErrors }) => {
  return state.merge({
    exercises: [],
    autoCompleteErrors,
  });
};

const setNavigation = (state, { navigation }) =>
  state.merge({
    navigation: {
      ...state.navigation,
      ...navigation,
    },
  });

const setSearch = (state, { search }) =>
  state.merge({
    search,
  });

const setSearchText = (state, { searchText }) =>
  state.merge({
    searchText,
  });

const setFilters = (state, { filters }) =>
  state.merge({
    filters: state.filters.merge(filters),
  });

const fetchExercisesFiltersSuccess = (state, { exercisesFilters }) =>
  state.merge({ exercisesFilters, errors: [] });

const setExercisesFiltersErrors = (state, { errors }) =>
  state.merge({
    exercisesFilters: [],
    errors,
  });

const setExercisesFiltersLoading = (state, { isLoadingExercisesFilters }) => {
  return state.merge({ isLoadingExercisesFilters });
};

const setActiveFilters = (state, activeFilters) => state.merge(activeFilters);

export const ExercisesReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_EXERCISES_SUCCESS]: fetchExercisesSuccess,
  [Types.SET_EXERCISES_LOADING]: setExercisesLoading,
  [Types.SET_EXERCISES_ERRORS]: setExercisesErrors,
  [Types.FETCH_AUTO_COMPLETE_SUCCESS]: fetchAutoCompleteSuccess,
  [Types.SET_AUTO_COMPLETE_LOADING]: setAutoCompleteLoading,
  [Types.SET_AUTO_COMPLETE_ERRORS]: setAutoCompleteErrors,
  [Types.SET_NAVIGATION]: setNavigation,
  [Types.SET_SEARCH]: setSearch,
  [Types.SET_SEARCH_TEXT]: setSearchText,
  [Types.SET_ACTIVE_FILTERS]: setActiveFilters,
  [Types.SET_FILTERS]: setFilters,
  [Types.FETCH_EXERCISES_FILTERS_SUCCESS]: fetchExercisesFiltersSuccess,
  [Types.SET_EXERCISES_FILTERS_LOADING]: setExercisesFiltersLoading,
  [Types.SET_EXERCISES_FILTERS_ERRORS]: setExercisesFiltersErrors,
});
